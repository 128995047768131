.Toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 90px;
  color: white;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
  z-index: 110;
}

.link {
  font-family: "Josefin Sans", sans-serif;
  flex-basis: 0;
  flex-grow: 1;
  opacity: 0;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  font-weight: 200;
  font-size: 14px;
  letter-spacing: 7px;
  text-align: center;
  cursor: pointer;
  animation-duration: 1s;
  animation-name: delay-text;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-delay: 5.5s;
}

.link:hover {
  font-weight: 400;
}

.active {
  font-weight: 400;
  cursor: pointer;
}

.logo {
  font-family: "NewYork";
  font-size: 55px;
  width: 150px;
  position: relative;
  display: flex;
  align-items: center;
  pointer-events: none;
  user-select: none;
}

.logo :nth-child(1) {
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  left: 0px;
  position: absolute;
  opacity: 0;
  transform: rotate(-90deg);
  animation-name: leftone;
  animation-duration: 1500ms;
  animation-delay: 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}

.key {
  animation-name: turn-key;
  animation-duration: 1500ms;
  animation-delay: 3.4s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}

.Toolbar .logo :nth-child(2) {
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);

  right: 0px;
  position: absolute;
  opacity: 0;
  transform: rotate(90deg);

  animation-name: rightone;
  animation-duration: 1500ms;
  animation-delay: 2s;

  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}

@keyframes turn-key {
  0% {
    transform: rotateX(0deg);
  }

  100% {
    transform: rotateX(360deg);
  }
}

.logo {
  height: 60px;
  object-fit: contain;
}

@keyframes leftone {
  from {
    left: -50px;
    position: absolute;
    opacity: 0;
    transform: rotate(-90deg);
  }
  to {
    left: -16px;
    position: absolute;
    opacity: 1;
    transform: rotate(0deg);
  }
}

@keyframes rightone {
  from {
    right: -50px;
    position: absolute;
    opacity: 0;
    transform: rotate(90deg);
  }
  to {
    right: -16px;
    position: absolute;
    opacity: 1;
    transform: rotate(0deg);
  }
}

@keyframes delay-text {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .Toolbar {
  }
  .link {
    display: none;
  }
}
