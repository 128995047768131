.Hero {
  height: 100vh;
  width: 100%;
  position: relative;
  z-index: 1;
  animation-name: hero;
  animation-duration: 0.8s;
  animation-iteration-count: 1;
  animation-delay: 4.3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  scroll-snap-align: center;
}

@keyframes hero {
  from {
    height: 100vh;
  }
  to {
    height: 90vh;
  }
}

@keyframes hero-gradient {
  from {
    background-color: rgba(0, 0, 0, 0.85);
    height: 100vh;
  }
  to {
    background-color: rgba(0, 0, 0, 0);
    height: 90vh;
  }
}

.img-content {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text-body {
  z-index: 100;
  width: 100%;
  height: 100%;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.85);
  animation-name: hero-gradient;
  animation-duration: 0.8s;
  animation-iteration-count: 1;
  animation-delay: 4.3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}

.gradient {
  position: relative;
  width: 100%;
  height: 100vh;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0.4) 20%,
      rgba(0, 0, 0, 0) 30%
    ),
    radial-gradient(
      50% 50% at 50% 50%,
      rgba(0, 0, 0, 0.6) 20%,
      rgba(0, 0, 0, 0) 100%
    );
  animation-name: hero;
  animation-duration: 0.8s;
  animation-iteration-count: 1;
  animation-delay: 4.3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}

.text-content {
  width: 700px;
  animation-name: fade;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}

.text-body .text-content .header {
  font-family: "Josefin Sans", sans-serif;
  color: white;
  font-weight: 300;
  font-size: 20px;
  letter-spacing: 25px;
  margin-bottom: 20px;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
}

.text-body .text-content .subtitle {
  font-family: "Newsreader", serif;
  color: white;
  font-weight: 300;
  font-size: 50px;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media screen and (max-width: 768px) {
  .text-content {
    width: 90%;
  }

  .text-body .text-content .header {
    font-family: "Josefin Sans", sans-serif;
    color: white;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 10px;
    margin-bottom: 20px;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  }

  .text-body .text-content .subtitle {
    font-family: "Newsreader", serif;
    color: white;
    font-weight: 300;
    font-size: 40px;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  }
}
