.AboutUs {
  padding-inline: 60px;
  padding-block: 40px;
}

.content-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  padding-inline: 50px;
}

.image-content {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AboutUs .text-content {
  width: 40%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 20px;
  margin-right: 30px;
}

.about {
}

.about p {
  font-family: "Newsreader";
  font-size: 15px;
  font-weight: 300;
  line-height: 1.3;
}

.image-content img {
  width: 100%;
  height: 400px;
  object-fit: cover;
  opacity: 0;
}

.AboutUs .content-container .text-content .header {
  font-family: "Josefin Sans";
  font-weight: 300;
  font-size: 15px;
  letter-spacing: 16px;
  margin-bottom: 10px;
}

.subtitle {
  font-family: "NewYork";
  font-size: 40px;
  margin-bottom: 15px;
}

.image-content img.visible {
  animation-name: photo-fade-in;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

@keyframes photo-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .AboutUs {
    padding-inline: 40px;
    padding-block: 50px;
  }
  .content-container {
    flex-direction: column;
    padding-inline: 0px;
  }
  .image-content {
    width: 100%;
    height: 320px;
    margin-top: 20px;
  }
  .image-content img {
    height: 100%;
  }
  .AboutUs .text-content {
    width: 100%;
    margin-right: 0px;
    padding: 0px;
  }
  .AboutUs .content-container .text-content .header {
    font-size: 12px;
    letter-spacing: 10px;
  }
  .subtitle {
    font-size: 30px;
  }
  .about p {
    font-size: 17px;
  }
}
