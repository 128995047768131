.Footer {
  height: 250px;
  background-color: #eee;
}

.contact-logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
}

.contact-logo .gg {
  font-size: 70px;
  font-family: "NewYork";
  letter-spacing: -75px;
  text-align: center;
  position: relative;
  left: -15px;
}

.contact-logo .gg-text {
  text-align: center;
  font-family: "Newsreader";
  font-size: 22px;
  font-weight: 300;
}

.social-media {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.main-contact-container {
  width: 500px;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.contact-info-container {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  padding-right: 40px;
}

.email .header {
  font-family: "Josefin Sans";
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 10px;
  margin-bottom: 10px;
}

.email .info {
  font-family: "Newsreader";
  font-size: 19px;
  font-weight: 500;
  font-style: italic;
  text-decoration: none;
  color: black;
}

.email {
  margin-block: 12px;
}

.contact-logo-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 20px;
}

.button {
  height: 25px;
  width: 25px;
  display: flex;
  margin-right: 10px;
  justify-content: center;
  align-items: center;
  background-color: #8f8f8f;
  border-radius: 50%;
}

.instagram {
  width: 50%;
  height: 50%;
}

.facebook {
  width: 50%;
  height: 50%;
}
.twitter {
  width: 50%;
  height: 40%;
}

@media (max-width: 768px) {
  .Footer {
    height: 400px;
  }

  .main-contact-container {
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .contact-info-container {
    border-right: none;
    padding-right: 0px;
    margin-bottom: 20px;
    justify-content: flex-start;
  }

  .contact-logo-container {
    margin-right: 0px;
  }

  .contact-logo {
    margin-bottom: 20px;
  }

  .contact-logo .gg {
    left: -38px;
  }

  .social-media {
    margin-bottom: 20px;
  }

  .email {
    text-align: center;
  }
}
