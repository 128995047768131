.TeamMember {
  width: 280px;
  height: 540px;
  margin-inline: 20px;
  margin-block: 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 0 0 auto;
  cursor: pointer;

  text-decoration: none;
}

.TeamMember:hover {
  filter: brightness(0.9);
}

.TeamMember img {
  height: 350px;
  width: 100%;
  object-fit: cover;
}

.member-data {
  text-align: start;
  padding-block: 15px;
  width: 100%;
}

.TeamMember .name {
  font-family: "NewYork";
  font-size: 28px;
  margin-bottom: 10px;
  color: black;
}

.TeamMember .title {
  font-family: "Josefin Sans", sans-serif;
  font-weight: 300;
  font-size: 13px;
  letter-spacing: 10px;
  color: black;
  margin-bottom: 20px;
}

.contact {
  font-family: "Newsreader", sans-serif;
  font-size: 13px;
  font-weight: 300;
  color: black;
  padding-block: 5px;
}

.contact-text {
  font-family: "Newsreader", sans-serif;
  font-size: 16px;
  font-weight: 300;
  color: black;
  text-decoration: none;
}

@media (max-width: 768px) {
  .contact-text {
    font-family: "Newsreader", sans-serif;
    font-size: 18px;
    font-weight: 300;
    color: black;
    text-decoration: none;
  }
}
