.OurTeam {
  border-left: 25px #d6dbd1 solid;
  border-right: 25px #d6dbd1 solid;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-inline: 30px;
  padding-block: 50px;
  margin-block: 50px;
}

.carousel {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: auto;
  opacity: 1;
  transition: 1s linear opacity;
}

.hidden {
  opacity: 0;
  transition: 1s linear opacity;
}
